import React from 'react';

const Logo = () => {
  return (
    <div className="group py-3">
      <img
        src="/assets/cyberwave-wide.svg"
        alt="Cyberwave"
        className="p-2 h-10 w-auto transition-[filter] duration-300"
        style={{ 
          filter: 'brightness(0) saturate(100%) invert(100%)'
        }}
      />
      <style jsx>{`
        .group:hover img {
          filter: brightness(0) saturate(100%) invert(77%) sepia(71%) saturate(2184%) hue-rotate(157deg) brightness(96%) contrast(98%);
        }
      `}</style>
    </div>
  );
}

export default Logo;
