import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/index.css';
import Header from './components/Header';
import Home from './components/Home';
import Research from './components/Research';
import MasterPlan from './components/MasterPlan';
import UseCases from './components/UseCases';
import HealthSafety from './components/HealthSafety';
import UnmannedSurveillance from './components/UnmannedSurveillance';
import RenewableEnergyInspection from './components/RenewableEnergyInspection';
import BridgeInfrastructureInspection from './components/BridgeInfrastructureInspection';
import AutomatedWelding from './components/AutomatedWelding';
import Technology from './components/Technology';
import Contact from './components/Contact';
import Footer from './components/Footer';
import BuildingFacadeCleaning from './components/BuildingFacadeCleaning';
import PowerLineInspection from './components/PowerLineInspection';
import About from './components/About';

function App() {
  return (
    <Router>
      <div className="bg-gray-900 text-white min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/research" element={<Research />} />
            <Route path="/masterplan" element={<MasterPlan />} />
            <Route path="/usecases" element={<UseCases />} />
            <Route path="/usecases/health-safety" element={<HealthSafety />} />
            <Route path="/usecases/unmanned-surveillance" element={<UnmannedSurveillance />} />
            <Route path="/usecases/renewable-energy-inspection" element={<RenewableEnergyInspection />} />
            <Route path="/usecases/bridge-infrastructure-inspection" element={<BridgeInfrastructureInspection />} />
            <Route path="/usecases/automated-welding" element={<AutomatedWelding />} />
            <Route path="/usecases/building-facade-cleaning" element={<BuildingFacadeCleaning />} />
            <Route path="/usecases/power-line-inspection" element={<PowerLineInspection />} />
            <Route path="/technology" element={<Technology />} />
            <Route path="/about" element={<About />} />
            <Route path="/about/masterplan" element={<MasterPlan />} />
            <Route path="/about/research" element={<Research />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
