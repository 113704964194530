import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Cpu, Code, Users, Database, Bot, Plane } from 'lucide-react';
import CodeTerminal from './CodeTerminal';

function Technology() {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const features = [
    {
      icon: <Cpu className="w-8 h-8 text-cyan-400" />,
      title: "Unified Integration Layer",
      description: "Our platform seamlessly connects with various hardware platforms, reducing integration time from weeks to hours."
    },
    {
      icon: <Brain className="w-8 h-8 text-cyan-400" />,
      title: "AI Controller Models",
      description: "Advanced AI models enable multimodal robotics capabilities, adapting to different tasks and environments."
    },
    {
      icon: <Database className="w-8 h-8 text-cyan-400" />,
      title: "Cloud AI Reasoning",
      description: "High-level orchestration and planning through our cloud-based AI system for optimal performance."
    }
  ];

  const platforms = [
    {
      icon: <Plane className="w-12 h-12 text-cyan-400" />,
      title: "Drone Fleet",
      description: "Autonomous aerial platforms for inspection, surveillance, and monitoring tasks."
    },
    {
      icon: <Bot className="w-12 h-12 text-cyan-400" />,
      title: "Ground Robots",
      description: "Versatile ground-based robots for various industrial applications."
    },
    {
      icon: <Code className="w-12 h-12 text-cyan-400" />,
      title: "Software Integration",
      description: "Flexible API and SDK for seamless integration with existing systems."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <motion.div 
        className="relative py-20 px-6 bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto">
          <motion.h1 
            className="text-5xl font-bold mb-6 text-white"
            {...fadeIn}
          >
            Cyberwave Technology
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-300 max-w-2xl"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            Our cutting-edge AI platform dramatically reduces integration costs and effort, 
            making advanced robotics accessible to manufacturers of all sizes.
          </motion.p>
        </div>
      </motion.div>

      {/* Code Examples Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto">
          <motion.div 
            className="max-w-3xl mx-auto text-center mb-12"
            {...fadeIn}
          >
            <h2 className="text-3xl font-bold mb-4 text-cyan-400">
              Deploy in Minutes
            </h2>
            <p className="text-gray-300 text-lg">
              Experience the simplicity of our platform with these example use cases. 
              Just a few lines of code to get started with advanced robotics automation.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.6 }}
          >
            <CodeTerminal />
          </motion.div>
        </div>
      </section>

      {/* Core Features Section */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto">
          <motion.h2 
            className="text-3xl font-bold mb-12 text-center text-cyan-400"
            {...fadeIn}
          >
            Three-Layer Architecture
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-gray-800 rounded-xl p-6 shadow-lg hover:shadow-cyan-900/20 transition-shadow"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-3 text-white">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Platform Components */}
      <section className="py-20 px-6">
        <div className="container mx-auto">
          <motion.h2 
            className="text-3xl font-bold mb-12 text-center text-cyan-400"
            {...fadeIn}
          >
            Platform Components
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-8">
            {platforms.map((platform, index) => (
              <motion.div
                key={platform.title}
                className="bg-gray-800 rounded-xl p-8 text-center shadow-lg hover:shadow-cyan-900/20 transition-shadow"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="mb-6 flex justify-center">{platform.icon}</div>
                <h3 className="text-xl font-semibold mb-3 text-white">{platform.title}</h3>
                <p className="text-gray-400">{platform.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Integration Benefits */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto text-center">
          <motion.h2 
            className="text-3xl font-bold mb-12 text-cyan-400"
            {...fadeIn}
          >
            Why Choose Our Platform?
          </motion.h2>
          <motion.div 
            className="max-w-3xl mx-auto bg-gray-800 rounded-xl p-8 shadow-lg"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            <ul className="text-left space-y-4">
              <li className="flex items-center text-gray-300">
                <div className="mr-4 text-cyan-400">✓</div>
                <span>Slash integration time from weeks to hours</span>
              </li>
              <li className="flex items-center text-gray-300">
                <div className="mr-4 text-cyan-400">✓</div>
                <span>Reduce deployment costs by up to 70%</span>
              </li>
              <li className="flex items-center text-gray-300">
                <div className="mr-4 text-cyan-400">✓</div>
                <span>Seamless integration with existing systems</span>
              </li>
              <li className="flex items-center text-gray-300">
                <div className="mr-4 text-cyan-400">✓</div>
                <span>AI-driven automation and optimization</span>
              </li>
              <li className="flex items-center text-gray-300">
                <div className="mr-4 text-cyan-400">✓</div>
                <span>Continuous learning and improvement</span>
              </li>
            </ul>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Technology;
