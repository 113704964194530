import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Lightbulb, Network, Cpu, Bot, LineChart } from 'lucide-react';

const Research = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const researchAreas = [
    {
      title: "AI & Machine Learning",
      icon: <Brain className="w-8 h-8 text-cyan-400" />,
      description: "Advancing the frontiers of artificial intelligence and machine learning for robotics applications."
    },
    {
      title: "Computer Vision",
      icon: <Lightbulb className="w-8 h-8 text-cyan-400" />,
      description: "Developing cutting-edge vision systems for real-time object detection and scene understanding."
    },
    {
      title: "Neural Networks",
      icon: <Network className="w-8 h-8 text-cyan-400" />,
      description: "Researching novel neural network architectures for improved robotic control and decision making."
    },
    {
      title: "Edge Computing",
      icon: <Cpu className="w-8 h-8 text-cyan-400" />,
      description: "Optimizing AI models for edge devices to enable real-time processing and reduced latency."
    },
    {
      title: "Robotics Integration",
      icon: <Bot className="w-8 h-8 text-cyan-400" />,
      description: "Creating seamless integration solutions for various robotic platforms and industrial systems."
    },
    {
      title: "Performance Analytics",
      icon: <LineChart className="w-8 h-8 text-cyan-400" />,
      description: "Developing metrics and analytics tools to measure and optimize automation performance."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[40vh] flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-cyan-500/10"></div>
        <div className="z-10 text-center">
          <h1 className="text-5xl font-bold mb-4">Research & Innovation</h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto px-4">
            Pushing the boundaries of AI and robotics through groundbreaking research and development
          </p>
        </div>
      </motion.div>

      {/* Research Areas Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.h2 
          className="text-3xl font-bold mb-12 text-center"
          {...fadeIn}
        >
          Our Research Focus Areas
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {researchAreas.map((area, index) => (
            <motion.div
              key={area.title}
              className="bg-gray-800 rounded-lg p-6 hover:bg-gray-750 transition-colors"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                {area.icon}
                <h3 className="text-xl font-semibold ml-3">{area.title}</h3>
              </div>
              <p className="text-gray-300">{area.description}</p>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <motion.div 
        className="bg-gradient-to-r from-cyan-500/20 to-blue-500/20 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">Interested in Collaborating?</h2>
          <p className="text-xl text-gray-300 mb-8">
            We're always looking for partners to push the boundaries of what's possible in robotics and AI
          </p>
          <button className="bg-cyan-400 text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-cyan-300 transition-colors">
            Get in Touch
          </button>
        </div>
      </motion.div>
    </div>
  );
}

export default Research;
