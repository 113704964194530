import React from 'react';
import { motion } from 'framer-motion';
import { Mail, MapPin, Phone, Globe } from 'lucide-react';

const Contact = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const contactInfo = [
    
    {
      icon: <Mail className="w-6 h-6 text-cyan-400" />,
      title: "Email",
      details: "info@cyberwave.com",
      action: "mailto:info@cyberwave.com"
    },
    {
      icon: <MapPin className="w-6 h-6 text-cyan-400" />,
      title: "Location",
      details: "Milan, Zurich and San Francisco",
      action: "https://www.google.com/maps/place/San Francisco+US"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[40vh] flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-cyan-500/10"></div>
        <div className="z-10 text-center">
          <h1 className="text-5xl font-bold mb-4">Get in Touch</h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto px-4">
            Contact us for any inquiries about our solutions and services
          </p>
        </div>
      </motion.div>

      <div className="max-w-7xl mx-auto px-4 py-16">
        {/* Contact Information */}
        <motion.div
          className="max-w-3xl mx-auto space-y-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div>
            <h2 className="text-3xl font-bold mb-6">Contact Information</h2>
            <p className="text-gray-300 mb-8">
              Reach out to us through any of these channels:
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {contactInfo.map((item, index) => (
              <motion.a
                key={item.title}
                href={item.action}
                target={item.title === "Location" ? "_blank" : undefined}
                rel={item.title === "Location" ? "noopener noreferrer" : undefined}
                className="flex items-center p-4 bg-gray-800 rounded-lg hover:bg-gray-750 transition-colors cursor-pointer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="p-3 bg-gray-700 rounded-full mr-4">
                  {item.icon}
                </div>
                <div>
                  <h3 className="font-semibold text-lg">{item.title}</h3>
                  <p className="text-gray-300">{item.details}</p>
                </div>
              </motion.a>
            ))}
          </div>

          <div className="mt-12">
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <Globe className="w-5 h-5 text-cyan-400 mr-2" />
                Global Presence
              </h3>
              <p className="text-gray-300">
                Support available across all major time zones
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Contact;
