import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Terminal, Plane as Drone, Bot, Brain } from 'lucide-react';

const useCases = {
  drone: {
    title: "Autonomous Drone",
    icon: <Drone className="w-5 h-5" />,
    code: `from cyberwave import Robot
# Step 1: Instantiate the drone
my_drone = Robot("dji/tello")
# Step 2: Connect to the drone
my_drone.connect(ip_address="192.168.1.10")

# Step 3: Execute high-level autonomous task
my_drone.takeoff()
my_drone.scan_environment() # Autonomous environment mapping
location = my_drone.find_object(instruction='red landing target') # Identify and locate the target
my_drone.fly_to(location) # Navigate directly above the target
my_drone.land() # Land gently onto the target`
  },
  robot: {
    title: "Robotic Arm",
    icon: <Bot className="w-5 h-5" />,
    code: `from cyberwave import Robot
# 1. Connection to the target hardware
robotic_arm = Robot("kuka/kr3_agilus")
robotic_arm.connect(ip_address="192.168.1.100")
robotic_arm.initialize_sensors(["camera", "force_sensor"]) # AI training from video demonstrations

# 2. Training
trainer = VideoTrainer(model_type="welding")
trainer.train_from_videos(["weld_example1.mp4", "weld_example2.mp"])

# 3. Task execution using the trained model
await perform_welding(robotic_arm, trainer.model)`
  },
  vision: {
    title: "Vision System",
    icon: <Brain className="w-5 h-5" />,
    code: `from cyberwave import VisionSystem, QualityControl
# 1. Initialize the vision system
vision = VisionSystem("quality_inspection")
vision.connect(camera_id="main_inspection")
vision.load_model("defect_detection_v2")

# 2. Configure quality parameters
qc = QualityControl(
    tolerance=0.95,
    inspection_areas=["welds", "surface_finish"]
)

# 3. Start continuous inspection
async def inspect_parts():
    while True:
        image = await vision.capture()
        results = vision.analyze(image)
        if qc.check_quality(results):
            print("Part passed inspection")
        else:
            print("Quality issues detected")`
  }
};

function CodeTerminal() {
  const [activeTab, setActiveTab] = useState('drone');

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="bg-gray-800/50 rounded-lg overflow-hidden border border-gray-700/50">
        {/* Terminal Header */}
        <div className="flex items-center justify-between px-4 py-2 bg-gray-800">
          <div className="flex items-center gap-2">
            <Terminal className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-400">cyberwave-terminal</span>
          </div>
          <div className="flex space-x-2">
            <div className="w-3 h-3 rounded-full bg-red-500" />
            <div className="w-3 h-3 rounded-full bg-yellow-500" />
            <div className="w-3 h-3 rounded-full bg-green-500" />
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="flex border-b border-gray-700/50">
          {Object.entries(useCases).map(([key, { title, icon }]) => (
            <button
              key={key}
              onClick={() => setActiveTab(key)}
              className={`flex items-center gap-2 px-4 py-2 text-sm transition-colors ${
                activeTab === key
                  ? 'text-cyan-400 border-b-2 border-cyan-400 bg-gray-800/50'
                  : 'text-gray-400 hover:text-gray-300 hover:bg-gray-800/30'
              }`}
            >
              {icon}
              {title}
            </button>
          ))}
        </div>

        {/* Code Content */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="p-4 font-mono text-sm"
          >
            <pre className="text-gray-300 whitespace-pre-wrap">
              {useCases[activeTab].code.split('\n').map((line, index) => (
                <div key={index} className="leading-6">
                  {line.startsWith('#') ? (
                    <span className="text-cyan-400/80">{line}</span>
                  ) : (
                    <span>{line}</span>
                  )}
                </div>
              ))}
            </pre>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

export default CodeTerminal; 