import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight, Bot, Zap, LineChart } from 'lucide-react';
import Hero from './Hero';
import Features from './Features';
import About from './About';

function Home() {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="relative">
        {/* Hero Video Section */}
        <motion.div 
          className="relative h-[60vh] sm:h-[70vh] md:h-[80vh] overflow-hidden"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/70 via-gray-900/50 to-gray-900 z-10" />
          <video 
            className="absolute top-0 left-0 w-full h-full object-cover"
            autoPlay 
            loop 
            muted 
            playsInline
          >
            <source src="https://storage.cloud.google.com/cyberwave-website/cyberwave-ai-assisted-v2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-20 container mx-auto px-4 h-full flex flex-col justify-center items-start">
            <motion.div 
              className="space-y-2 mb-6"
              {...fadeIn}
            >
              <span className="inline-block text-cyan-400 text-lg sm:text-xl font-medium tracking-wide">
                Next-Gen Industrial Automation
              </span>
              <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-light text-white max-w-4xl leading-[1.1]">
                Transforming Industry with 
                <span className="font-normal text-cyan-400"> AI-Defined </span> 
                Robotics
              </h1>
            </motion.div>
            <motion.p 
              className="text-lg sm:text-xl md:text-2xl text-gray-300 max-w-2xl mb-8 sm:mb-10 font-light"
              {...fadeIn}
              transition={{ delay: 0.2 }}
            >
              Experience frictionless automation where AI defines every aspect of robotic control,
              making advanced automation accessible to manufacturers of all sizes.
            </motion.p>
            <motion.div
              className="flex flex-col sm:flex-row items-start sm:items-center gap-4"
              {...fadeIn}
              transition={{ delay: 0.4 }}
            >
              <Link 
                to="/technology" 
                className="group inline-flex items-center gap-2 bg-cyan-500 hover:bg-cyan-600 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-300 text-base"
              >
                Explore Our Technology
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
              <Link
                to="/contact"
                className="group inline-flex items-center gap-2 bg-gray-800/80 hover:bg-gray-700/80 text-gray-300 hover:text-white font-medium py-2.5 px-6 rounded-lg border border-gray-700/50 hover:border-gray-600/50 transition-all duration-300 text-base"
              >
                Contact Us
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </Link>
            </motion.div>
          </div>
        </motion.div>
      </div>

      {/* Key Benefits */}
      <motion.section 
        className="py-16 sm:py-20 relative overflow-hidden"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8">
            {[
              {
                icon: <Bot className="w-8 h-8 text-cyan-400" />,
                title: "AI-Defined Control",
                description: "Advanced artificial intelligence that defines and adapts robotic control systems in real-time."
              },
              {
                icon: <Zap className="w-8 h-8 text-cyan-400" />,
                title: "Rapid Integration",
                description: "Seamless integration with existing infrastructure, reducing setup time by 70%."
              },
              {
                icon: <LineChart className="w-8 h-8 text-cyan-400" />,
                title: "Real-time Analytics",
                description: "Comprehensive performance insights for continuous optimization."
              }
            ].map((benefit, index) => (
              <motion.div
                key={benefit.title}
                className="relative p-6 rounded-lg bg-gray-800/50 backdrop-blur-sm border border-gray-700/50 hover:border-gray-600/50 transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-medium text-white mb-2">{benefit.title}</h3>
                <p className="text-gray-300 leading-relaxed">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      {/* Features Grid */}
      <motion.section 
        className="py-16 sm:py-20 bg-gradient-to-b from-gray-800/50 to-transparent"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-12 sm:mb-16">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-light mb-4 text-white">
              Revolutionizing 
              <span className="text-cyan-400 font-normal"> Industrial Automation</span>
            </h2>
            <p className="text-lg sm:text-xl text-gray-300 font-light leading-relaxed">
              Discover how our AI-driven solutions are transforming manufacturing processes
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
            {[
              {
                title: "Smart Control",
                description: "Advanced AI systems for precise and adaptive control.",
                image: "/images/drone-high.webp"
              },
              {
                title: "Easy Integration",
                description: "Seamless integration with existing infrastructure.",
                image: "/images/drone-manufacturing.webp"
              },
              {
                title: "Data Analytics",
                description: "Real-time insights for optimal performance.",
                image: "/images/helmets.webp"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                className="bg-gray-800/50 backdrop-blur-sm rounded-lg overflow-hidden border border-gray-700/50 hover:border-cyan-500/50 hover:bg-gray-800/80 transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
              >
                <div className="h-48 sm:h-56 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent z-10" />
                  <img 
                    src={feature.image} 
                    alt={feature.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-medium mb-2 text-white hover:text-cyan-400 transition-colors">
                    {feature.title}
                  </h3>
                  <p className="text-gray-300 leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <Hero />
      <Features />
      <About />

      {/* Call to Action */}
      <motion.section 
        className="py-20 sm:py-24 bg-gradient-to-b from-gray-800 to-gray-900 relative overflow-hidden"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        <div className="container mx-auto px-4 relative z-10">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-light mb-6 text-white">
              Ready to Transform Your Operations?
            </h2>
            <p className="text-lg sm:text-xl text-gray-300 mb-8 sm:mb-10 font-light leading-relaxed max-w-2xl mx-auto">
              Join leading manufacturers who have already embraced the future of automation with Cyberwave.
            </p>
            <Link 
              to="/contact" 
              className="group inline-flex items-center gap-2 bg-cyan-500 hover:bg-cyan-600 text-white font-medium py-2.5 px-6 rounded-lg transition-all duration-300 text-base"
            >
              Get Started Today
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-cyan-500/5 to-transparent pointer-events-none" />
      </motion.section>
    </div>
  );
}

export default Home;
