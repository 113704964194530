import React from 'react';

const Features = () => {
  const features = [
    { title: 'Cut Deployment Time', description: 'Slash integration time and cost from weeks to hours with our unified AI platform' },
    { title: 'Make Robots Flexible', description: 'Easily reconfigure robotic systems to adapt to new tasks and changing business needs' },
    { title: 'Smarter Orchestration', description: 'AI-driven coordination that makes automation cost-effective even for mid-market manufacturers' },
  ];

  return (
    <section id="features" className="py-10 bg-gray-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center text-cyan-400">Our Features</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="bg-gray-700 p-6 rounded-2xl shadow-lg transform hover:scale-105 transition-transform duration-200">
              <h3 className="text-xl font-semibold mb-4 text-cyan-300">{feature.title}</h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
