import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Cpu, Brain, Code, Database, Bot, Plane } from 'lucide-react';
import { Button } from "./ui/button";

const About = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const manufacturingEras = [
    {
      era: "Batch Manufacturing",
      period: "1900s–1980s",
      driver: "Economies of scale",
      goal: "Cost-efficiency at volume",
      process: "Linear production lines",
      challenges: "High inventory, long setup"
    },
    {
      era: "Lean Manufacturing",
      period: "1980s–2020s",
      driver: "Waste reduction",
      goal: "Efficiency and flow",
      process: "Pull systems",
      challenges: "Limited adaptability"
    },
    {
      era: "AI-defined Manufacturing",
      period: "2020s+",
      driver: "Real-time recomposability",
      goal: "Flexibility and autonomy",
      process: "AI-driven optimization",
      challenges: "Complex integration"
    }
  ];

  const recomposablePrinciples = [
    {
      title: "Modularity First",
      description: "Physical and digital components must be swappable and interoperable. Robots and tools are designed like Lego bricks: combine, split, and repurpose easily.",
      icon: <Bot className="w-6 h-6 text-cyan-400" />
    },
    {
      title: "Software-Defined Everything",
      description: "Capabilities are assigned through software, not hardware changes. One robotic arm = infinite task potential through downloaded skills.",
      icon: <Code className="w-6 h-6 text-cyan-400" />
    },
    {
      title: "Agentic Orchestration",
      description: "Autonomous agents manage floor layout, task distribution, and adapt in real time. LLMs act as high-level planners for coordinating mixed fleets.",
      icon: <Brain className="w-6 h-6 text-cyan-400" />
    },
    {
      title: "Sim2Real Feedback Loops",
      description: "Everything has a digital twin. Simulations test new flows, and results are applied live within minutes.",
      icon: <Database className="w-6 h-6 text-cyan-400" />
    },
    {
      title: "Plug-and-Produce Protocols",
      description: "Standardization of connectors, power, and APIs allows any vendor's robot to integrate. Similar to how USB standardized personal computing peripherals.",
      icon: <Plane className="w-6 h-6 text-cyan-400" />
    },
    {
      title: "Autonomous Adaptation",
      description: "The system constantly reconfigures itself based on demand, available tools, and constraints. Predictive and reactive, with minimal human oversight.",
      icon: <Cpu className="w-6 h-6 text-cyan-400" />
    }
  ];

  const ProcessArrow = () => (
    <div className="hidden md:flex items-center justify-center w-16">
      <ArrowRight className="w-8 h-8 text-cyan-400" />
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[50vh] flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-cyan-500/10"></div>
        <div className="z-10 text-center max-w-4xl px-4">
          <h1 className="text-5xl font-bold mb-4">Manufacturing Evolution</h1>
          <p className="text-xl text-gray-300 mb-6">
            From Batch to Lean to Recomposable Manufacturing
          </p>
          <p className="text-lg text-gray-300">
            Cyberwave is at the forefront of the Recomposable Manufacturing revolution, 
            enabling manufacturers to transition from rigid, fixed production lines to 
            dynamic, self-adapting systems that can be reconfigured in real-time.
          </p>
        </div>
      </motion.div>

      {/* Cyberwave's Role */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto max-w-4xl">
          <motion.div 
            className="text-center mb-12"
            {...fadeIn}
          >
            <h2 className="text-3xl font-bold mb-6 text-cyan-400">Cyberwave's Role in Manufacturing Evolution</h2>
            <p className="text-lg text-gray-300 mb-8">
              As manufacturing enters the Recomposable era, Cyberwave provides the essential 
              AI platform that makes this transformation possible. Our technology bridges the 
              gap between traditional automation and the future of manufacturing.
            </p>
          </motion.div>
          <div className="grid md:grid-cols-2 gap-8">
            <motion.div
              className="bg-gray-800 rounded-xl p-6 shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-white">The Challenge</h3>
              <p className="text-gray-300 mb-4">
                Traditional manufacturing systems are rigid and expensive to modify. 
                Each change requires significant downtime and reprogramming, making 
                it difficult for manufacturers to adapt to changing demands.
              </p>
              <p className="text-gray-300">
                This inflexibility has created a bottleneck, preventing 76% of mid-market 
                manufacturers from adopting automation solutions.
              </p>
            </motion.div>
            <motion.div
              className="bg-gray-800 rounded-xl p-6 shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-white">Our Solution</h3>
              <p className="text-gray-300 mb-4">
                Cyberwave's AI platform transforms industrial robots into an interconnected 
                flexible workforce. Our technology enables:
              </p>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>Real-time system reconfiguration</li>
                <li>Zero-shot task learning across different robotic platforms</li>
                <li>Seamless integration of new hardware and software components</li>
                <li>Autonomous adaptation to changing production needs</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Manufacturing Eras */}
      <section className="py-20 px-6">
        <div className="container mx-auto">
          <motion.h2 
            className="text-3xl font-bold mb-16 text-center text-cyan-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Evolution of Manufacturing
          </motion.h2>
          <div className="flex flex-col md:flex-row items-stretch justify-between gap-8">
            {manufacturingEras.map((era, index) => (
              <React.Fragment key={era.era}>
                <motion.div
                  className={`flex-1 bg-gray-800/50 rounded-xl p-8 shadow-lg border ${
                    index === 2 ? 'border-cyan-400/50 bg-cyan-950/20' : 'border-gray-700'
                  }`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                >
                  {index === 2 && (
                    <div className="mb-6 flex justify-center">
                      <img 
                        src="/assets/cyberwave-wide.svg" 
                        alt="Cyberwave" 
                        className="h-8 w-auto invert brightness-0"
                      />
                    </div>
                  )}
                  <div>
                    <h3 className={`text-2xl font-bold mb-2 ${
                      index === 2 ? 'text-cyan-400' : 'text-white'
                    }`}>{era.era}</h3>
                    <p className="text-cyan-400 text-lg mb-4">{era.period}</p>
                    <div className="space-y-3">
                      <div>
                        <h4 className="text-gray-300 font-semibold mb-1">Driver</h4>
                        <p className="text-white">{era.driver}</p>
                      </div>
                      <div>
                        <h4 className="text-gray-300 font-semibold mb-1">Goal</h4>
                        <p className="text-white">{era.goal}</p>
                      </div>
                      <div>
                        <h4 className="text-gray-300 font-semibold mb-1">Process</h4>
                        <p className="text-white">{era.process}</p>
                      </div>
                      <div>
                        <h4 className="text-gray-300 font-semibold mb-1">Challenges</h4>
                        <p className="text-white">{era.challenges}</p>
                      </div>
                    </div>
                  </div>
                  {index === 2 && (
                    <div className="mt-6 p-4 bg-cyan-500/10 rounded-lg border border-cyan-400/20">
                      <h4 className="text-cyan-400 font-semibold mb-2">Our Innovation</h4>
                      <ul className="list-disc list-inside text-gray-300 mt-2 space-y-1">
                        <li>Self-learning robotics</li>
                        <li>Real-time optimization</li>
                        <li>Dynamic allocation</li>
                        <li>Predictive maintenance</li>
                      </ul>
                    </div>
                  )}
                </motion.div>
                {index < 2 && <ProcessArrow />}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>

      {/* Recomposable Principles */}
      <section className="py-20 px-6 bg-gray-800/50">
        <div className="container mx-auto">
          <motion.h2 
            className="text-3xl font-bold mb-12 text-center text-cyan-400"
            {...fadeIn}
          >
            Principles of Recomposable Manufacturing
          </motion.h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {recomposablePrinciples.map((principle, index) => (
              <motion.div
                key={principle.title}
                className="bg-gray-800 rounded-xl p-6 shadow-lg hover:shadow-cyan-900/20 transition-shadow"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <div className="flex items-center gap-3 mb-4">
                  {principle.icon}
                  <h3 className="text-xl font-semibold text-white">{principle.title}</h3>
                </div>
                <p className="text-gray-300">{principle.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section id="about" className="py-10 bg-gray-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-cyan-400">About Cyberwave</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-gray-800 p-8 rounded-2xl shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Our Mission</h3>
              <p className="text-gray-300 mb-6">
                At Cyberwave, we're solving the biggest challenge in physical automation: the integration bottleneck that makes automation inaccessible for 76%+ of mid-market manufacturers.
              </p>
              <p className="text-gray-300 mb-6">
                Our mission is to transform industrial robots into an interconnected flexible workforce through our AI platform that slashes integration effort from weeks to hours.
              </p>
            </div>
            
            <div className="bg-gray-800 p-8 rounded-2xl shadow-lg">
              <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Our Goal</h3>
              <p className="text-gray-300 mb-6">
                Our goal is to deploy autonomous robo-workers to support us on a global scale, leveraging Italian expertise in robotics.
              </p>
              <p className="text-gray-300 mb-6">
                We aim to create a future where advanced robotics and AI work seamlessly alongside humans, enhancing our capabilities and allowing us to focus on more creative and fulfilling tasks.
              </p>
            </div>
          </div>
          
          <div className="mt-12 bg-gray-800 p-8 rounded-2xl shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-cyan-300">Why Choose Cyberwave?</h3>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Cutting-edge AI and robotics technology</li>
              <li>Italian expertise in precision engineering</li>
              <li>Commitment to enhancing global productivity</li>
              <li>Focus on creating safe and efficient autonomous systems</li>
              <li>Dedication to pushing the boundaries of what's possible in robotics</li>
            </ul>
          </div>
          
          <div className="mt-12 text-center">
            <p className="text-xl text-gray-300 mb-6">
              Join us in shaping the future of work and industry with Cyberwave's innovative robotic solutions.
            </p>
            <a href="/technology"> <Button variant="default" className="bg-cyan-500 hover:bg-cyan-600">
              Learn More About Our Technology
            </Button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
