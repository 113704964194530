import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Logo from './Logo';

function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-800/95 text-white backdrop-blur-sm sticky top-0 z-50 shadow-lg">
      <div className="container mx-auto flex items-center justify-between h-14 px-4 sm:px-6 lg:px-8">
        <NavLink to="/" className="flex items-center">
          <Logo />
        </NavLink>

        {/* Mobile menu button */}
        <button
          onClick={toggleMenu}
          className="lg:hidden p-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-cyan-400"
        >
          {isMenuOpen ? (
            <X className="h-6 w-6" />
          ) : (
            <Menu className="h-6 w-6" />
          )}
        </button>

        {/* Desktop Navigation */}
        <nav className="hidden lg:block h-full">
          <ul className="flex space-x-6 xl:space-x-10 h-full items-center">
            {[
              { to: "/", label: "Home" },
              { to: "/technology", label: "Technology" },
              {
                to: "/about",
                label: "About",
                dropdown: [
                  { to: "/about", label: "Overview" },
                  { to: "/about/masterplan", label: "Master Plan" },
                  { to: "/about/research", label: "Research" },
                ],
              },
              {
                to: "/usecases",
                label: "Use Cases",
                dropdown: [
                  { to: "/usecases/health-safety", label: "Health & Safety" },
                  { to: "/usecases/unmanned-surveillance", label: "Unmanned Surveillance" },
                  { to: "/usecases/renewable-energy-inspection", label: "Renewable Energy Inspection" },
                  { to: "/usecases/bridge-infrastructure-inspection", label: "Bridge & Infrastructure Inspection" },
                  { to: "/usecases/building-facade-cleaning", label: "Building Facade Cleaning" },
                  { to: "/usecases/power-line-inspection", label: "Power Line Inspection" },
                ],
              },
              { to: "/contact", label: "Contact" },
            ].map(({ to, label, dropdown }) => (
              <li key={to} className="relative group h-full flex items-center">
                <NavLink 
                  to={to} 
                  className={({ isActive }) => 
                    `hover:text-cyan-400 transition-colors h-full flex items-center text-sm xl:text-base font-medium border-b-2 px-1 xl:px-2 ${
                      isActive ? 'border-cyan-400 text-cyan-400' : 'border-transparent'
                    }`
                  }
                >
                  {label}
                </NavLink>
                {dropdown && (
                  <ul className="absolute left-0 top-full mt-0 w-64 bg-gray-800/95 backdrop-blur-sm rounded-b-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-50">
                    {dropdown.map((item) => (
                      <li key={item.to}>
                        <NavLink
                          to={item.to}
                          className={({ isActive }) =>
                            `block px-4 py-2.5 text-sm text-white hover:bg-gray-700 hover:text-cyan-400 ${
                              isActive ? 'border-l-4 border-cyan-400 bg-gray-700' : ''
                            }`
                          }
                        >
                          {item.label}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Mobile Navigation */}
        <nav
          className={`lg:hidden fixed inset-y-0 right-0 transform ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          } w-64 bg-gray-900/98 backdrop-blur-md shadow-2xl transition-transform duration-300 ease-in-out z-50`}
        >
          <div className="p-4">
            <button
              onClick={toggleMenu}
              className="absolute top-3 right-3 p-2 rounded-md hover:bg-gray-700/70 focus:outline-none focus:ring-2 focus:ring-cyan-400"
            >
              <X className="h-6 w-6" />
            </button>
            <ul className="mt-8 space-y-2 bg-gray-800/80 backdrop-blur-sm rounded-xl p-3 shadow-lg ring-1 ring-white/10">
              {[
                { to: "/", label: "Home" },
                { to: "/technology", label: "Technology" },
                { to: "/about", label: "About" },
                { to: "/about/masterplan", label: "Master Plan" },
                { to: "/about/research", label: "Research" },
                { to: "/usecases", label: "Use Cases" },
                { to: "/contact", label: "Contact" },
              ].map(({ to, label }) => (
                <li key={to}>
                  <NavLink
                    to={to}
                    onClick={toggleMenu}
                    className={({ isActive }) =>
                      `block px-4 py-2.5 rounded-lg text-base transition-all duration-200 ${
                        isActive
                          ? 'bg-gray-700/70 text-cyan-400 shadow-md'
                          : 'text-white hover:bg-gray-700/50 hover:text-cyan-400 hover:shadow-sm'
                      }`
                    }
                  >
                    {label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
