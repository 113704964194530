import React from 'react';
import { motion } from 'framer-motion';
import { Factory, Package, Truck, Shield, Wrench, Microscope } from 'lucide-react';

const UseCases = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const industries = [
    {
      title: "Manufacturing",
      icon: <Factory className="w-8 h-8 text-cyan-400" />,
      description: "Automated assembly lines and quality control systems powered by AI for increased efficiency and precision.",
      metrics: ["30% increase in productivity", "50% reduction in defects", "24/7 operation capability"]
    },
    {
      title: "Logistics",
      icon: <Package className="w-8 h-8 text-cyan-400" />,
      description: "Smart warehousing solutions with autonomous robots for picking, packing, and inventory management.",
      metrics: ["40% faster order fulfillment", "99.9% picking accuracy", "60% reduced labor costs"]
    },
    {
      title: "Supply Chain",
      icon: <Truck className="w-8 h-8 text-cyan-400" />,
      description: "End-to-end supply chain optimization using predictive analytics and automated routing.",
      metrics: ["25% reduced delivery times", "15% fuel savings", "Real-time tracking"]
    },
    {
      title: "Quality Assurance",
      icon: <Shield className="w-8 h-8 text-cyan-400" />,
      description: "AI-powered quality control systems that detect defects with superhuman accuracy.",
      metrics: ["99.8% defect detection", "80% faster inspections", "Zero false positives"]
    },
    {
      title: "Maintenance",
      icon: <Wrench className="w-8 h-8 text-cyan-400" />,
      description: "Predictive maintenance systems that prevent downtime and optimize equipment performance.",
      metrics: ["70% reduced downtime", "40% maintenance savings", "5x longer equipment life"]
    },
    {
      title: "R&D Labs",
      icon: <Microscope className="w-8 h-8 text-cyan-400" />,
      description: "Automated research facilities with precise control and data collection capabilities.",
      metrics: ["3x faster experiments", "100% reproducibility", "Big data insights"]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[40vh] flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-cyan-500/10"></div>
        <div className="z-10 text-center">
          <h1 className="text-5xl font-bold mb-4">Real-World Applications</h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto px-4">
            Discover how Cyberwave's AI-driven automation transforms industries and delivers measurable results
          </p>
        </div>
      </motion.div>

      {/* Industries Grid */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.h2 
          className="text-3xl font-bold mb-12 text-center"
          {...fadeIn}
        >
          Industries We Transform
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {industries.map((industry, index) => (
            <motion.div
              key={industry.title}
              className="bg-gray-800 rounded-lg p-6 hover:bg-gray-750 transition-colors"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                {industry.icon}
                <h3 className="text-xl font-semibold ml-3">{industry.title}</h3>
              </div>
              <p className="text-gray-300 mb-4">{industry.description}</p>
              <ul className="space-y-2">
                {industry.metrics.map((metric, idx) => (
                  <li key={idx} className="flex items-center text-cyan-400 text-sm">
                    <span className="w-1.5 h-1.5 bg-cyan-400 rounded-full mr-2"></span>
                    {metric}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <motion.div 
        className="bg-gradient-to-r from-cyan-500/20 to-blue-500/20 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Operations?</h2>
          <p className="text-xl text-gray-300 mb-8">
            Join leading companies that have already revolutionized their processes with Cyberwave
          </p>
          <button className="bg-cyan-400 text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-cyan-300 transition-colors">
            Schedule a Demo
          </button>
        </div>
      </motion.div>
    </div>
  );
}

export default UseCases;
