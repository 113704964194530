import React from 'react';
import { motion } from 'framer-motion';
import { Target, Rocket, Zap, Workflow, Layers, Scale } from 'lucide-react';
import { Link } from 'react-router-dom';

const MasterPlan = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const phases = [
    {
      title: "Phase 1: Foundation",
      icon: <Target className="w-8 h-8 text-cyan-400" />,
      current: true,
      timeline: "2024",
      objectives: [
        "Launch AI-driven automation platform",
        "Establish key industry partnerships",
        "Deploy initial pilot programs",
        "Build core team and infrastructure"
      ]
    },
    {
      title: "Phase 2: Expansion",
      icon: <Rocket className="w-8 h-8 text-cyan-400" />,
      current: false,
      timeline: "2025",
      objectives: [
        "Scale platform capabilities",
        "Enter new market segments",
        "Enhance AI algorithms",
        "Expand global presence"
      ]
    },
    {
      title: "Phase 3: Innovation",
      icon: <Zap className="w-8 h-8 text-cyan-400" />,
      current: false,
      timeline: "2026",
      objectives: [
        "Launch next-gen features",
        "Develop advanced AI models",
        "Create industry standards",
        "Pioneer new solutions"
      ]
    }
  ];

  const pillars = [
    {
      title: "Technology Leadership",
      icon: <Workflow className="w-8 h-8 text-cyan-400" />,
      description: "Pioneering advanced AI and automation solutions that set new industry standards."
    },
    {
      title: "Scalable Architecture",
      icon: <Layers className="w-8 h-8 text-cyan-400" />,
      description: "Building robust, flexible systems that grow with our clients' needs."
    },
    {
      title: "Market Impact",
      icon: <Scale className="w-8 h-8 text-cyan-400" />,
      description: "Delivering measurable value and transformative results across industries."
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Hero Section */}
      <motion.div 
        className="relative h-[40vh] flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="absolute inset-0 bg-cyan-500/10"></div>
        <div className="z-10 text-center">
          <h1 className="text-5xl font-bold mb-4">Our Master Plan</h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto px-4">
            A strategic roadmap to revolutionize industrial automation through AI-driven innovation
          </p>
          <Link 
            to="/contact"
            className="inline-block mt-6 bg-cyan-400 text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-cyan-300 transition-colors"
          >
            Get in Touch
          </Link>
        </div>
      </motion.div>

      {/* Strategic Pillars */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <motion.h2 
          className="text-3xl font-bold mb-12 text-center"
          {...fadeIn}
        >
          Strategic Pillars
        </motion.h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {pillars.map((pillar, index) => (
            <motion.div
              key={pillar.title}
              className="bg-gray-800 rounded-lg p-6 hover:bg-gray-750 transition-colors"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <div className="flex items-center mb-4">
                {pillar.icon}
                <h3 className="text-xl font-semibold ml-3">{pillar.title}</h3>
              </div>
              <p className="text-gray-300">{pillar.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Roadmap */}
        <motion.h2 
          className="text-3xl font-bold mb-12 text-center"
          {...fadeIn}
        >
          Development Roadmap
        </motion.h2>

        <div className="space-y-8">
          {phases.map((phase, index) => (
            <motion.div
              key={phase.title}
              className="relative"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className={`bg-gray-800 rounded-lg p-6 border-l-4 ${phase.current ? 'border-cyan-400' : 'border-gray-700'}`}>
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    {phase.icon}
                    <h3 className="text-xl font-semibold ml-3">{phase.title}</h3>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm ${phase.current ? 'bg-cyan-400/20 text-cyan-400' : 'bg-gray-700 text-gray-300'}`}>
                    {phase.timeline}
                  </span>
                </div>
                <ul className="space-y-2">
                  {phase.objectives.map((objective, idx) => (
                    <li key={idx} className="flex items-center text-gray-300">
                      <span className="w-1.5 h-1.5 bg-cyan-400 rounded-full mr-2"></span>
                      {objective}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Vision Statement */}
      <motion.div 
        className="bg-gradient-to-r from-cyan-500/20 to-blue-500/20 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">Our Vision</h2>
          <p className="text-xl text-gray-300 mb-8">
            To create a world where advanced automation is accessible to businesses of all sizes, 
            driving innovation and efficiency across industries
          </p>
          <Link 
            to="/contact" 
            className="inline-block bg-cyan-400 text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-cyan-300 transition-colors"
          >
            Contact Us
          </Link>
        </div>
      </motion.div>
    </div>
  );
}

export default MasterPlan;
